import React from "react"
import "./Footer.css"

declare const window: any

const Footer: React.FunctionComponent = () => {

	const manageCookies = (e: React.MouseEvent<HTMLAnchorElement>) => {
		e.preventDefault()
		window.tarteaucitron.userInterface.openPanel()
	}

	return (
		<>
			<footer className="fr-footer" role="contentinfo" id="footer">
				<h2 className="footer-title">Pied de page</h2>
				<div className="fr-footer__top">
					<div className="fr-container">
						<div className="fr-grid-row fr-grid-row--start fr-grid-row--gutters">
							<div className="fr-col-12 fr-col-sm-6 fr-col-md-4">
								<h3 className="fr-footer__top-cat">Besoin d’aide pour ce service en ligne&nbsp;?</h3>
								<ul className="fr-footer__top-list">
									<li>
										<a className="fr-footer__top-link"
											href="https://www.interieur.gouv.fr/contact/signaler-probleme-sur-site">
											Avez-vous une question ou un problème&nbsp;?
										</a>
									</li>
									<li>
										<a className="fr-footer__top-link"
											href="https://www.service-public.fr/particuliers/vosdroits/N47#1">
											Toute l’information sur cette démarche
										</a>
									</li>
								</ul>
							</div>
							<div className="fr-col-12 fr-col-sm-6 fr-col-md-4">
								<h3 className="fr-footer__top-cat">Nos engagements</h3>
								<ul className="fr-footer__top-list">
									<li>
										<a className="fr-footer__top-link" href="https://www.service-public.fr/P10125">
											Accessibilité de la démarche : partiellement conforme
										</a>
									</li>
									<li>
										<a className="fr-footer__top-link" href="https://www.service-public.fr/P10129">
											Données personnelles et sécurité de la démarche
										</a>
									</li>
									<li>
										<a className="fr-footer__top-link" href="https://www.service-public.fr/P10128">
											Conditions générales d&rsquo;utilisation de la démarche
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div className="fr-container">
					<div className="fr-footer__body">
						<div className="fr-footer__brand fr-enlarge-link">
							<a
								href="https://www.service-public.fr/"
								title="Accueil Service-Public.fr">
								<p className="fr-logo">République <br />Française</p>
							</a>
						</div>
						<div className="fr-footer__content">
							<p className="fr-footer__content-desc">
								Service Public vous informe et vous oriente vers les services qui permettent de connaître vos obligations, d’exercer vos droits et de faire vos démarches du quotidien.
							</p>
							<p className="fr-footer__content-desc">
								<span>Il est édité par la </span>
								<a
									href="https://www.dila.premier-ministre.gouv.fr/"
									rel="noopener noreferrer external"
									target="_blank"
									title="Direction de l'information légale et administrative - Nouvelle fenêtre">
									Direction de l’information légale et administrative
								</a>
								et réalisé en partenariat avec les administrations nationales et locales.
							</p>
							<ul className="fr-footer__content-list">
								<li>
									<a
										className="fr-footer__content-link"
										href="http://legifrance.gouv.fr"
										rel="noopener noreferrer external"
										target="_blank"
										title="legifrance.gouv.fr - nouvelle fenêtre">
										legifrance.gouv.fr
									</a>
								</li>
								<li>
									<a
										className="fr-footer__content-link"
										href="http://gouvernement.fr"
										rel="noopener noreferrer external"
										target="_blank"
										title="gouvernement.fr - nouvelle fenêtre">
										gouvernement.fr
									</a>
								</li>
								<li>
									<a
										className="fr-footer__content-link"
										href="http://service-public.fr"
										rel="noopener noreferrer external"
										target="_blank"
										title="service-public.fr - nouvelle fenêtre">
										service-public.fr
									</a>
								</li>
								<li>
									<a
										className="fr-footer__content-link"
										href="http://data.gouv.fr"
										rel="noopener noreferrer external"
										target="_blank"
										title="data.gouv.fr - nouvelle fenêtre">
										data.gouv.fr
									</a>
								</li>
							</ul>
						</div>
					</div>
					<div className="fr-footer__bottom">
						<ul className="fr-footer__bottom-list">
							<li className="fr-footer__bottom-item">
								<a className="fr-footer__bottom-link" href="https://www.service-public.fr/P10003">Plan du site</a>
							</li>
							<li className="fr-footer__bottom-item">
								<a className="fr-footer__bottom-link" href="https://www.service-public.fr/P10025">Mentions légales</a>
							</li>
							<li className="fr-footer__bottom-item">
								<a className="fr-footer__bottom-link" href="https://www.service-public.fr/P10000">Accessibilité : partiellement conforme</a>
							</li>
							<li className="fr-footer__bottom-item">
								<a className="fr-footer__bottom-link" href="https://www.service-public.fr/P10125">Accessibilité des services en ligne</a>
							</li>
							<li className="fr-footer__bottom-item">
								<a className="fr-footer__bottom-link" href="https://www.service-public.fr/P10001">Données personnelles et sécurité</a>
							</li>
							<li className="fr-footer__bottom-item">
								<a className="fr-footer__bottom-link" href="https://www.service-public.fr/P10050">Conditions générales d’utilisation</a>
							</li>
							<li className="fr-footer__bottom-item">
								<a className="fr-footer__bottom-link" href="/" onClick={manageCookies}>Gestion des cookies</a>
							</li>
						</ul>
						<div className="fr-footer__bottom-copy">
							<p>Sauf mention explicite de propriété intellectuelle détenue par des tiers, les contenus de ce site sont proposés sous <a href="https://github.com/etalab/licence-ouverte/blob/master/LO.md" target="_blank" rel="noopener noreferrer external" title="licence etalab-2.0 - nouvelle fenêtre">licence etalab-2.0</a>
							</p>
						</div>
					</div>
				</div>
			</footer>
		</>
	)
}

export default Footer
